
  import { Component, Vue, Emit, Watch, Prop } from "vue-property-decorator";

  export interface AccordionColumn {
    showInHeader?: boolean;
    type: string;
    label?: string;
    width?: string;
    center?: boolean;
    headerCenter?: boolean;
  }

  @Component
  export default class GeneralAccordion extends Vue {
    @Prop({ default: "" }) title!: string;
    @Prop() total?: number;
    @Prop({ default: () => [] }) columns?: AccordionColumn[];
    @Prop() isOpen?: boolean;

    open: boolean = true;

    created() {
      if (this.isOpen !== undefined) {
        this.open = this.total !== 0 ? this.isOpen : false;
      }
    }

    /**
     * Toggle the current listen open or closed
     */
    toggle() {
      if (this.total === 0) return;

      this.open = !this.open;
    }

    get isClickable() {
      return this.total !== 0;
    }

    @Emit("on-open")
    handleListOpen() {
      return this.open;
    }

    @Emit("on-close")
    handleListClosed() {
      return this.open;
    }

    @Watch("open")
    handleOpenChange() {
      if (this.open) {
        this.handleListOpen();
      }

      this.handleListClosed();
    }
  }
